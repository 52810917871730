


















































import { PropertyFloorType, PropertySubdivisionVisualizationHint } from '@/types/iot-portal';
import { RequiredProp } from '@/util/prop-decorators';
import { groupBy, partition } from 'lodash';
import { Component, Inject, Vue } from 'vue-property-decorator';
import {
  NodeEditorAddPropertySubdivision,
  NodeEditorRemovePropertySubdivision,
  NODE_EDITOR_ADD_PROPERTY_SUBDIVISION,
  NODE_EDITOR_REMOVE_PROPERTY_SUBDIVISION,
} from '../model';
import { AddFloorAction, Floor, Property, PropertySubdivision } from './model';
import PropertyFloorEditor from './PropertyFloorEditor.vue';
import { v4 as uuidv4 } from 'uuid';
import AddPropertyFloorModal from './AddPropertyFloorModal.vue';

function levelCompare(a: Floor, b: Floor): number {
  return b.level - a.level;
}

function propertySubdivisionCompare(a: PropertySubdivision, b: PropertySubdivision): number {
  return a.order - b.order;
}

@Component({
  components: {
    PropertyFloorEditor,
    AddPropertyFloorModal,
  },
  data() {
    return {
      addFloorAction: undefined,
    };
  },
})
export default class PropertyBuildingEditor extends Vue {
  @RequiredProp()
  private readonly property!: Property;

  private dropdown = false;

  private addFloorAction?: AddFloorAction;

  private get floorsByType(): Partial<Record<PropertyFloorType, Floor[]>> {
    return Object.fromEntries(
      Object.entries(groupBy(this.property.floors, ({ type }) => type)).map(([type, floors]) => {
        floors = floors
          .map((floor) => {
            const [left, right] = partition(
              this.getPropertySubdivisionsForFloorLevel(floor.level),
              (subdivision) => subdivision.visualizationHint !== PropertySubdivisionVisualizationHint.STAIRWELL,
            );
            return {
              ...floor,
              left: left.sort(propertySubdivisionCompare),
              right: right.sort(propertySubdivisionCompare),
            };
          })
          .sort(levelCompare);

        return [type, floors] as [PropertyFloorType, Floor[]];
      }),
    );
  }

  private get propertySubdivisions(): PropertySubdivision[] {
    return this.property.children.items;
  }

  private getPropertySubdivisionsForFloorLevel(floorLevel: number): PropertySubdivision[] {
    return this.propertySubdivisions.filter((child) => child.floorLevel === floorLevel) as PropertySubdivision[];
  }

  private onDropdownOutsideClick(): void {
    if (this.dropdown) {
      this.dropdown = false;
    }
  }

  private addRoofFloor(floorData: Pick<Floor, 'name' | 'level'>): void {
    if (floorData.level < 0) {
      throw new Error('Floor level and type mismatch');
    }
    this.addFloor({ ...floorData, type: PropertyFloorType.ROOF });
  }

  private addOvergroundFloor(floorData: Pick<Floor, 'name' | 'level'>): void {
    if (floorData.level < 0) {
      throw new Error('Floor level and type mismatch');
    }
    this.addFloor({ ...floorData, type: PropertyFloorType.OVERGROUND });
  }

  private addUndergroundFloor(floorData: Pick<Floor, 'name' | 'level'>): void {
    if (floorData.level >= 0) {
      throw new Error('Floor level and type mismatch');
    }
    this.addFloor({ ...floorData, type: PropertyFloorType.UNDERGROUND });
  }

  private addFloor(newFloor: Pick<Floor, 'name' | 'level' | 'type'>): void {
    if (this.property.floors.findIndex((floor) => floor.level === newFloor.level) >= 0) {
      throw new Error('Floor level already exists');
    }
    this.property.floors.push({ ...newFloor, __typename: 'PropertyFloor' });
    this.property.floors = [...this.property.floors];

    this.$emit('on-edit');

    // by default, a floor has stairs
    this.addPropertySubdivision(newFloor.level, PropertySubdivisionVisualizationHint.STAIRWELL);
  }

  private addPropertySubdivision(floorLevel: number, visualizationHint: PropertySubdivisionVisualizationHint): void {
    const propertySubdivision: PropertySubdivision = {
      __typename: 'PropertySubdivision',
      id: `temp_${uuidv4()}`,
      name:
        visualizationHint === PropertySubdivisionVisualizationHint.APARTMENT
          ? 'Neue Wohnung'
          : visualizationHint === PropertySubdivisionVisualizationHint.ROOM
          ? 'Neuer Raum'
          : 'Treppenhaus',
      order: 0,
      visualizationHint,
      floorLevel,
      rooms: [],
      externalId: null,
      externalType: null,
      size: PropertySubdivisionVisualizationHint.STAIRWELL ? null : 0, //staircases do not have a value
      position: null,
    };
    this.nodeEditorAddPropertySubdivision(propertySubdivision);
  }

  private removePropertySubdivision(id: string): void {
    if (!this.property) {
      return;
    }
    this.nodeEditorRemovePropertySubdivision(id);
  }

  private get canAddRoof(): boolean {
    return this.property.floors.find((floor) => floor.type === PropertyFloorType.ROOF) === undefined ?? true;
  }

  private removeFloor(level: number): void {
    this.property.floors.splice(
      this.property.floors.findIndex((floor) => floor.level === level),
      1,
    );
    this.$emit('on-edit');
  }

  private editFloor(floor: Floor): void {
    const existingFloor = this.property.floors.find((propertyFloor) => propertyFloor.level === floor.level);
    if (!existingFloor) {
      return;
    }
    existingFloor.name = floor.name;
    this.property.floors = [...this.property.floors];
    this.$emit('on-edit');
  }

  @Inject(NODE_EDITOR_ADD_PROPERTY_SUBDIVISION)
  private nodeEditorAddPropertySubdivision!: NodeEditorAddPropertySubdivision;

  @Inject(NODE_EDITOR_REMOVE_PROPERTY_SUBDIVISION)
  protected nodeEditorRemovePropertySubdivision!: NodeEditorRemovePropertySubdivision;
}
