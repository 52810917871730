import { NodeEditorNode } from '../components/node-editor/model';
import { AppAdminCustomerManagePropertiesNodeEditorPropertySubdivisionFragment } from '../components/node-editor/property/__generated__/AppAdminCustomerManagePropertiesNodeEditorPropertySubdivisionFragment';
import {
  NodeTreeChangesManagerEditAfterAddPropertyInput,
  NodeTreeChangesManagerEditAfterAddPropertySubdivisionInput,
  NodeTreeChangesManagerEditPropertyInput,
  NodeTreeChangesManagerTechnicalContactData,
} from '../node-tree-changes-manager/model';
import NodeTreeChangesManager from '../node-tree-changes-manager/NodeTreeChangesManager';
import { AppAdminInputSelectContractControlContractFragment } from '@/features/app-admin/components/input-select-contract-control/__generated__/AppAdminInputSelectContractControlContractFragment';

type Contract = AppAdminInputSelectContractControlContractFragment;

function getTechnicalContactName(technicalContactData: NodeTreeChangesManagerTechnicalContactData): string {
  return technicalContactData.name ?? `${technicalContactData.firstName ?? ''} ${technicalContactData.lastName ?? ''}`;
}

export function getNodeEditorNodeWithChanges(
  node: NodeEditorNode,
  changesManager: NodeTreeChangesManager,
  contracts: Contract[],
): NodeEditorNode {
  const change = changesManager.get(node.id);
  if (change) {
    if (change.type === 'Add') {
      const treeNode = change.input.treeNode;
      if (node.__typename === 'Property' || node.__typename === 'PropertyGroup') {
        const contract = contracts.find(({ id }) => id === change.input.treeNode.contractId);
        if (contract !== undefined) {
          node.contract = contract;
        }
      }
      if (treeNode.editAfterAdd) {
        Object.assign(node, treeNode.editAfterAdd.input);
        const technicalContactData = (treeNode.editAfterAdd as NodeTreeChangesManagerEditAfterAddPropertyInput)
          .technicalContactData;
        if (technicalContactData) {
          Object.assign(node, {
            technicalContact: {
              ...technicalContactData,
              name: getTechnicalContactName(technicalContactData),
            },
          });
        }
      }
    } else if (change.type === 'Edit') {
      const input = change.input as NodeTreeChangesManagerEditPropertyInput;
      Object.assign(node, input.input);
      if (input.technicalContactData) {
        Object.assign(node, {
          technicalContact: {
            ...input.technicalContactData,
            name: getTechnicalContactName(input.technicalContactData),
          },
        });
      }
    }
  }

  if (node.__typename === 'Property' || node.__typename === 'PropertyGroup') {
    // remove subdivisions
    node.children.items = node.children.items.filter((propertySubdivision) => {
      const propertyChange = changesManager.get(propertySubdivision.id);
      if (propertyChange && propertyChange.type == 'Remove') {
        return false;
      }

      return true;
    });

    //edit subdivisions
    node.children.items.forEach((propertySubdivision) => {
      const propertyChange = changesManager.get(propertySubdivision.id);
      if (!propertyChange) {
        return;
      }
      if (propertyChange.type === 'Edit') {
        Object.assign(propertySubdivision, propertyChange.input.input);
      }
    });
    // add subdivisions
    const addedPropertySubdivisions = changesManager.getAddChangesByParentId(node.id);
    node.children.items = node.children.items.concat(
      addedPropertySubdivisions.map(({ input }) => {
        const editAfterAdd = input.treeNode.editAfterAdd as NodeTreeChangesManagerEditAfterAddPropertySubdivisionInput;
        return {
          __typename: 'PropertySubdivision',
          id: input.temporaryId,
          name: editAfterAdd.input.name,
          size: editAfterAdd.input.size,
          order: editAfterAdd.input.order,
          visualizationHint: editAfterAdd.input.visualizationHint,
          floorLevel: editAfterAdd.input.floorLevel,
          rooms: editAfterAdd.input.rooms,
        } as AppAdminCustomerManagePropertiesNodeEditorPropertySubdivisionFragment;
      }),
    );
  }

  return node;
}
